import { IconProps } from "../types";

export const HamburgerMenu = ({
  className,
  height,
  width,
  color,
}: IconProps) => {
  return (
    <svg
      width={width || "28"}
      height={height || "19"}
      viewBox="0 0 28 19"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.87"
        d="M0 17.1111C0 17.9702 0.696446 18.6667 1.55556 18.6667H26.4444C27.3036 18.6667 28 17.9702 28 17.1111C28 16.252 27.3036 15.5556 26.4444 15.5556H1.55555C0.696445 15.5556 0 16.252 0 17.1111ZM0 9.33333C0 10.1924 0.696446 10.8889 1.55556 10.8889H26.4444C27.3036 10.8889 28 10.1924 28 9.33333C28 8.47422 27.3036 7.77778 26.4444 7.77778H1.55555C0.696445 7.77778 0 8.47422 0 9.33333ZM1.55555 0C0.696445 0 0 0.696446 0 1.55556C0 2.41466 0.696446 3.11111 1.55556 3.11111H26.4444C27.3036 3.11111 28 2.41466 28 1.55556C28 0.696446 27.3036 0 26.4444 0H1.55555Z"
        fill={color || "#003032"}
      />
    </svg>
  );
};
